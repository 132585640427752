<template>
  <div class="data-shop-card">
    <div class="body-content">
      <div class="com-header">
        <div class="title com-title">资料商城</div>
        <div class="nav-box">
          <tabNav :navList="navList" v-model="type" @input="handleGetData" />
        </div>
        <div class="search-box"></div>
      </div>
      <div class="com-btn-box">
        <div class="btn-theme btn-theme-yellow" @click="handleToRouter('/material/msgDemand')">
          <div class="btn-icon">
            <img src="@/assets/home/data-icon.svg" alt />
          </div>
          <div class="btn-text">方案征集</div>
        </div>
      </div>
      <div class="com-body">
        <a-spin :spinning="spinning">
          <a-empty class="empty" v-if="!dataList.length" />
          <div class="card-content" v-else>
            <div class="card-item" v-for="item in dataList" :key="item.id" @click="handleToOpen(item)">
              <template v-if="type === 'video'">
                <microClassCard :cardInfo="item" />
              </template>
              <template v-else-if="type === 'videoList'">
                <videoItem :cardInfo="item" />
              </template>
              <template v-else>
                <div class="avatar" v-if="item.thumbImg">
                  <!-- <a-avatar shape="square" :size="150" :src="item.thumbImg" /> -->
                  <img v-imgLazy="item.thumbImg" style="width: 150px; height: 150px" />
                </div>
                <div class="title-box">
                  <div class="data-type">{{ item.fileType }}</div>
                  <div class="data-name text-overflow">{{ item.title }}</div>
                </div>
                <div class="data-size">文件大小：{{ item.fileSize }}</div>
                <!-- <div class="data-price">
                <div class="vip-price">￥{{ item.memberPrice }}</div>
                <div class="price">非会员：￥{{ item.price }}</div>
              </div> -->
                <div class="data-price">
                  <!-- <div class="vip-price">价格：￥{{ item.price }}</div> -->
                  <div class="vip-type">会员专享</div>
                </div>
              </template>

            </div>
          </div>
        </a-spin>
      </div>
      <div class="load-more">
        <div
          class="btn-theme-more"
          @click="handleToPush"
        >
          查看更多
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tabNav from './tab-nav.vue'
import { employmentLearnlist } from '@/api/msg'
import { getVideoList, getVideoListByPage } from '@/api/video'
import microClassCard from '@/components/microClassCard.vue'
import videoItem from '@/components/videoItem.vue'

export default {
  name: 'DataShop',
  inject: ['handleToRouter', 'handleToBaiscRouter'],
  components: {
    tabNav,
    microClassCard,
    videoItem
  },
  data () {
    return {
      navList: [
        {
          label: '全部',
          value: '0'
        },
        {
          label: '技术资料',
          value: '1001'
        },
        {
          label: '管理资料',
          value: '1002'
        },
        {
          label: '安全资料',
          value: '1003'
        },
        {
          label: '视频微课',
          value: 'video'
        },
        {
          label: '执技云课堂',
          value: 'videoList'
        }
      ],
      dataList: [],
      spinning: false,
      type: '0'
    }
  },
  created () {
    this.handleGetData()
  },
  methods: {
    handleGetData (value) {
      this.spinning = true
      if (value === 'video') {
        getVideoList({
          pageIndex: 1,
          pageSize: 10
        })
          .then((res) => {
            this.dataList = res.data
          })
          .finally(() => {
            this.spinning = false
          })
      } else if (value === 'videoList') {
        Promise.all([
          getVideoListByPage({
            pageIndex: 1,
            pageSize: 5,
            videoType: 2,
            status: 0
          }),
          getVideoListByPage({
            pageIndex: 1,
            pageSize: 5,
            videoType: 3,
            status: 0
          })
        ])
          .then((res) => {
            this.dataList = [...res[0].data, ...res[1].data]
          })
          .finally(() => {
            this.spinning = false
          })
      } else {
        employmentLearnlist({
          pageIndex: 1,
          pageSize: 10,
          categoryId: this.type === '0' ? null : this.type
        })
          .then((res) => {
            this.dataList = res.data
          })
          .finally(() => {
            this.spinning = false
          })
      }
    },
    handleToOpen (item) {
      if (this.type === 'video') {
        window.open(`/videoMicro/videoMicroClassInfo?id=${item.id}`)
      } else if (this.type === 'videoList') {
        window.open(`/video/details?id=${item.id}`)
      } else {
        window.open(`/material/material-details?id=${item.id}`)
      }
    },
    handleToPush () {
      let url = '/material/material-shop'
      if (this.type === 'video') {
        url = '/videoMicro/videoMicroClass'
      }
      if (this.type === 'videoList') {
        url = '/video/list'
      }
      this.handleToBaiscRouter(url)
    }
  }
}
</script>

<style lang="less">
.data-shop-card {
  width: 100%;
  background-image: url('../../../assets/home/data-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .body-content {
    width: 1200px;
    margin: 0 auto;
    .com-header {
      display: flex;
      align-items: center;
      height: 84px;
      justify-content: space-between;
      border-bottom: 1px solid #dddddd;
      .nav-box {
        height: 100%;
        flex: 1;
        .nav-item,
        .tab-line {
          width: 100px !important;
        }
      }
    }
    .com-btn-box {
      height: 110px;
      display: flex;
      align-items: center;
    }
    .com-body {
      height: 650px;
      display: flex;
      align-items: center;
      justify-content: center;
      .card-content {
        height: 650px;
        width: 1200px;
        display: flex;
        flex-wrap: wrap;
      }
    }
    .card-item {
      margin: 0 18px 18px 0;
      width: 225px;
      height: 307px;
      padding: 28px 20px;
      background: #ffffff;
      &:nth-child(5n) {
        margin-right: 0px;
      }
      .avatar {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      .title-box,
      .data-size,
      .data-price {
        margin: 10px 0px;
      }
      .vip-type {
        padding: 1px 5px;
        background: #1677ff;
        border-radius: 2px;
        color: #ffffff;
        font-size: 12px;
      }
      .title-box {
        display: flex;
        align-items: center;
        width: 100%;
        .data-type {
          font-weight: 550;
          font-size: 12px;
          color: #1677ff;
          background: rgba(22, 119, 255, 0.1);
          border-radius: 2px;
          padding: 2px 6px;
        }
        .data-name {
          font-weight: 550;
          font-size: 14px;
          color: #333333;
          margin-left: 10px;
        }
      }
      .data-size {
        font-weight: 300;
        font-size: 12px;
        color: #666666;
      }
      .data-price {
        display: flex;
        align-items: center;
        .vip-price {
          font-weight: 400;
          font-size: 12px;
          color: #e21f1f;
        }
        .price {
          font-weight: 300;
          font-size: 12px;
          color: #999999;
          margin-left: 10px;
        }
      }
    }
    .load-more {
      height: 110px;
      width: 100%;
      .btn-theme-more {
        border: 1px solid #dddddd;
        border-radius: 40px;
        width: 160px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        margin: 0 auto;
        color: #999999;
        cursor: pointer;
      }
    }
  }
}
</style>
