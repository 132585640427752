import request from '@/utils/request'

// 提交资料发布需求
export function EnterpriseMaterialDemateController (parameter) {
  return request({
    url: '/api/enterprise/employmentLearn/EnterpriseMaterialDemateController',
    method: 'post',
    data: parameter
  })
}

// 学习资料收藏列表
export function appLearnfindUserCollect (parameter) {
  return request({
    url: '/api/enterprise/employmentLearn/enterpriseLearnMaterialController/findUserCollect',
    method: 'get',
    params: parameter
  })
}

// 学习资料列表
export function employmentLearnfindPopMaterial (parameter) {
  return request({
    url: '/api/enterprise/employmentLearn/enterpriseLearnMaterialController/findPopMaterial',
    method: 'get',
    params: parameter
  })
}

// 查询已购买的学习资料
export function findPurchaseHistory (parameter) {
  return request({
    url: '/api/enterprise/employmentLearn/enterpriseLearnMaterialController/findPurchaseHistory',
    method: 'get',
    params: parameter
  })
}

// 学习资料列表
export function employmentLearnlist (parameter) {
  return request({
    url: '/api/enterprise/employmentLearn/enterpriseLearnMaterialController/findByCategory',
    method: 'get',
    params: parameter
  })
}
// 收藏学习资料
export function employmentLearnUserCollect (parameter) {
  return request({
    url: `/api/enterprise/employmentLearn/enterpriseLearnMaterialController/userCollect/?materialId=${parameter.materialId}`,
    method: 'post'
  })
}

// 资料详情
export function employmentLearnUserDetail (parameter) {
  return request({
    url: `/api/enterprise/employmentLearn/enterpriseLearnMaterialController/${parameter.id}`,
    method: 'get'
  })
}

// 资料评价列表
export function getEvaluationList (params) {
  return request({
    url: `/api/enterprise/employmentEvaluate/enterpriseDataEvaluationController/queryById`,
    method: 'get',
    params: {
      ...params
    }
  })
}

// 资料收藏
export function userCollect (materialId) {
  return request({
    url: `/api/enterprise/employmentLearn/enterpriseLearnMaterialController/userCollect`,
    method: 'post',
    data: null,
    params: {
      materialId
    }
  })
}

// 查询资料类型
export function employmentLearnStyle (parameter) {
  return request({
    url: `/api/enterprise/employmentLearn/enterpriseCategoryController/find`,
    method: 'get',
    params: parameter
  })
}

// 根据一级类型的id查询资料类型树
export function templateMaterialFindTree (parameter) {
  return request({
    url: `/api/enterprise/employmentLearn/enterpriseCategoryController/findTree`,
    method: 'get',
    params: parameter
  })
}

// 资料商品管理-资料类型树
export const getMaterialTree = () => {
  return request.get('/api/plat/employmentLearn/platCategoryController/findTree')
      .then(response => response)
}

// 分页查询发布的资料需求
export function EnterpriseMaterialfindRelease (parameter) {
  return request({
    url: `/api/enterprise/employmentLearn/EnterpriseMaterialDemateController/findRelease`,
    method: 'get',
    params: parameter
  })
}
// 修改发布的资料需求
export function EnterpriseMaterialupdateStatus (parameter) {
  return request({
    url: `/api/enterprise/employmentLearn/EnterpriseMaterialDemateController/updateStatus`,
    method: 'PUT',
    data: parameter
  })
}
// 分页查询资料需求响应的用户
export function EnterpriseMaterialfindResponseUsers (parameter) {
  return request({
    url: `/api/enterprise/employmentLearn/enterpriseDemandResponseController/findResponseUsers`,
    method: 'get',
    params: parameter
  })
}

// 查询资料需求详情
export function EnterpriseMaterialDemateControllerviewDetails (parameter) {
  return request({
    url: `/api/enterprise/employmentLearn/EnterpriseMaterialDemateController/${parameter.id}`,
    method: 'get'
  })
}
// 查看人员详情
export function employmentLearnDetail (parameter) {
  return request({
    url: `/api/enterprise/employmentEmploy/enterpriseEmploymentManagementController/queryUserInfoByUserId`,
    method: 'get',
    params: parameter
  })
}

export function employmentLearnModflyStuate (parameter) {
  return request({
    url: `/api/enterprise/employmentLearn/enterpriseDemandResponseController`,
    method: 'PUT',
    data: parameter
  })
}
